<script setup lang="ts">
const userStore = useUser()
const { isLoggedIn, userCustomerGroupId } = storeToRefs(userStore)

const userConfigStore = useUserConfig()
const { customerGroupId, priceCategoryId } = storeToRefs(userConfigStore)

const { data: productsSetsData } = await useLazyAsyncData(
  'configurator-homepage',
  () =>
    Promise.all([
      createProductSetsRepository().getBySlug('arcus', '241'),
      createProductSetsRepository().getBySlug('flexi-light', 'chl-br-f95'),
    ]),
  {
    watch: [isLoggedIn, userCustomerGroupId, customerGroupId, priceCategoryId],
  }
)

const gridItems = computed(() => productsSetsData.value ?? [])

const localePath = useLocalePath()
</script>

<template>
  <div class="bg-black py-10 text-white">
    <div class="container">
      <TypographyH2Homepage class="mb-8">
        {{ $t('homepage.configurator.title') }}
      </TypographyH2Homepage>
      <div>
        <!-- <video
          src="https://ldseating.jagu.dev/file/nodes/carouselSlide/image/0001-0300.mp4"
          autoplay
          loop
          muted
          class="mb-8 aspect-auto h-full w-full object-cover"
        /> -->
        <NuxtImg
          provider="ipx"
          src="/img/jpg/configurator.jpg"
          sizes="100vw sm:600px md:800px lg:1000px xl:1200px"
          class="mb-8 aspect-auto h-full w-full object-cover"
        ></NuxtImg>
      </div>
      <div class="mb-8">
        <div
          class="mx-auto overflow-hidden border-y-4 border-neutral-700 2xl:w-full"
        >
          <div class="-mb-1 -ml-2 -mr-3 grid sm:grid-cols-2 lg:grid-cols-3">
            <div
              class="flex h-full w-full flex-col justify-between rounded-none border-b-4 border-l-0 border-r-4 border-t-0 border-neutral-700 p-2"
            >
              <p class="font-pragmatica-ext text-3xl font-bold lg:text-4xl">
                {{ $t('homepage.configurator.perex') }}
              </p>
              <p class="font-pragmatica-ext text-lg font-bold lg:text-xl">
                {{ $t('homepage.configurator.description') }}
              </p>
            </div>

            <UiCard
              v-for="item in gridItems"
              :key="item.nid"
              class="h-full w-full rounded-none border-b-4 border-l-0 border-r-4 border-t-0 border-neutral-700 bg-black p-2"
            >
              <NuxtLink
                :to="
                  item.productLine?.urlSlug && item.urlSlug
                    ? localePath(`/${item.productLine.urlSlug}/${item.urlSlug}`)
                    : undefined
                "
                class="no-underline"
              >
                <div
                  class="group flex h-full flex-col gap-3 p-2 hover:bg-neutral-700"
                >
                  <div>
                    <p class="font-pragmatica-ext text-xl font-bold">
                      {{ item.productLine?.title }}
                    </p>
                    <p class="font-pragmatica-ext text-sm font-bold">
                      {{ item.title }}
                    </p>
                  </div>
                  <div class="mx-auto">
                    <NuxtImg
                      v-if="item.products[0]?.images[0]?.url"
                      :src="withHost(item.products[0]?.images[0]?.url)"
                      sizes="100vw sm:300px"
                      class="h-full w-full object-contain"
                    />
                    <div
                      v-else
                      class="flex h-full w-full items-center justify-center"
                    >
                      <Icon
                        class="text-xl text-white"
                        :name="'fa6-solid:image'"
                      />
                    </div>
                  </div>
                  <div
                    class="flex min-h-8 flex-row items-end justify-between gap-5"
                  >
                    <!-- <p
                      v-if="showPrices && item.minimalFormattedPrice"
                      class="font-pragmatica-ext text-sm font-bold"
                    >
                      {{ $t('base.products.from') }}
                      {{ item.minimalFormattedPrice }}
                    </p> -->
                    <div v-if="item.readyForConfigurator" class="ml-auto">
                      <UiButtonWhiteOutline>
                        {{ $t('base.products.setDetail.hero.configure') }}
                      </UiButtonWhiteOutline>
                    </div>
                  </div>
                </div>
              </NuxtLink>
            </UiCard>
          </div>
        </div>
      </div>
      <div class="mx-auto block w-fit">
        <NuxtLink
          class="text-sm no-underline"
          :to="localePath({ name: 'products' })"
        >
          <UiAnimatedUnderlineText line-color="white">
            {{ $t('homepage.configurator.seeMore') }}
          </UiAnimatedUnderlineText>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

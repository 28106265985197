<script setup lang="ts">
const localePath = useLocalePath()
</script>

<template>
  <div class="relative w-full overflow-hidden bg-[#48C98B] lg:min-h-[900px]">
    <div class="container relative z-10 py-10">
      <div class="flex max-lg:flex-col lg:items-baseline lg:justify-between">
        <TypographyH2Homepage>
          {{ $t('homepage.sustainability.title') }}
        </TypographyH2Homepage>
        <div class="flex flex-col gap-8">
          <p class="font-pragmatica-ext max-w-md text-xl font-bold max-lg:pb-5">
            {{ $t('homepage.sustainability.perex') }}
          </p>
          <NuxtLink :to="localePath({ name: 'sustainability' })">
            <UiButtonBlackOutline>
              {{ $t('homepage.sustainability.cta') }}
            </UiButtonBlackOutline>
          </NuxtLink>
        </div>
      </div>
    </div>
    <div
      class="mx-auto max-lg:-mt-20 max-lg:max-w-xl lg:absolute lg:-left-32 lg:-top-20 xl:left-1/3 xl:-translate-x-1/3"
    >
      <img src="/img/jpg/udrzitelnost/chair.png" />
    </div>
  </div>
</template>

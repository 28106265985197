<script setup lang="ts">
const localePath = useLocalePath()

const i = ref(0)

onMounted(() => {
  setInterval(() => {
    i.value++
  }, 3000)
})

const { t } = useI18n()

const checks = [
  {
    label: t('homepage.about.people.igor_hurab.position'),
    x: 'left-[6%]',
    y: 'top-[78%] 2xl:top-[70%]',
    name: t('homepage.about.people.igor_hurab.name'),
  },
  {
    label: t('homepage.about.people.jan_hurab.position'),
    x: 'left-[27.5%]',
    y: 'top-[82%] 2xl:top-[72%]',
    name: t('homepage.about.people.jan_hurab.name'),
  },
  {
    label: t('homepage.about.people.jitka_hurabova.position'),
    x: 'left-[45%]',
    y: 'top-[80%] 2xl:top-[68%]',
    name: t('homepage.about.people.jitka_hurabova.name'),
  },
  {
    label: t('homepage.about.people.jakub_hurab.position'),
    x: 'left-[63%]',
    y: 'top-[84%] 2xl:top-[80%]',
    name: t('homepage.about.people.jakub_hurab.name'),
  },
  {
    label: t('homepage.about.people.dalibor_jakubec.position'),
    x: 'left-[80%]',
    y: 'top-[80%] 2xl:top-[70%]',
    name: t('homepage.about.people.dalibor_jakubec.name'),
  },
]
</script>

<template>
  <div class="relative overflow-hidden">
    <div class="relative overflow-hidden">
      <img
        class="pointer-events-none -mb-[20%] h-full w-full select-none object-cover max-lg:-mt-[20%] 2xl:-mt-[15%]"
        width="1920"
        height="1080"
        src="/img/jpg/ldseating-rodina-ext.png"
      />
      <div
        v-for="(check, index) in checks"
        class="group absolute duration-300 max-lg:hidden"
        :class="[`${check.x} ${check.y}`]"
      >
        <span
          class="absolute size-7 rounded-full border-2 border-white group-hover:opacity-0"
        >
          <span
            class="absolute left-1/2 top-1/2 h-0.5 w-4 -translate-x-1/2 -translate-y-1/2 bg-white"
          ></span>
          <span
            class="absolute left-1/2 top-1/2 h-4 w-0.5 -translate-x-1/2 -translate-y-1/2 bg-white"
          ></span>
        </span>
        <div
          class="relative z-20 hidden max-w-xs -translate-y-[calc(100%-28px)] border-2 border-white bg-black bg-opacity-70 p-2 text-white group-hover:block lg:text-lg"
          variant="soft"
        >
          <p class="font-pragmatica-ext mb-2 text-xs font-bold">
            {{ check.name }}
          </p>
          <p class="text-sm text-balance">
            {{ check.label }}
          </p>
        </div>
      </div>
    </div>
    <div
      class="absolute left-0 top-0 hidden h-full w-full bg-gradient-to-b from-transparent via-transparent to-black max-lg:hidden"
    ></div>
    <div class="left-0 top-20 w-full max-lg:pt-5 lg:absolute lg:text-white">
      <div class="container">
        <TypographyH2Homepage
          class="left-1/2 top-4 mb-8 w-full max-lg:container max-lg:absolute max-lg:-translate-x-1/2 max-lg:pt-5 max-lg:text-white"
        >
          {{ $t('homepage.about.title') }}
        </TypographyH2Homepage>
        <div
          class="relative z-10 flex justify-between gap-x-10 gap-y-5 max-lg:pb-5 max-md:flex-col"
        >
          <p
            class="font-pragmatica-ext max-w-3xl text-balance text-2xl font-bold"
          >
            {{ $t('homepage.about.perex') }}
          </p>
          <div>
            <p class="mb-10">
              {{ $t('homepage.about.description') }}
            </p>
            <NuxtLink :to="localePath({ name: 'about' })">
              <UiButtonWhiteOutline>
                {{ $t('homepage.about.cta') }}
              </UiButtonWhiteOutline>
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { getResourceUrl } = useResources()

// TODO get all pages
const { data: categories } = await useLazyAsyncData(() =>
  createProductsRepository().getAllCategories()
)

const localePath = useLocalePath()
</script>

<template>
  <section class="py-16">
    <div class="container">
      <div class="mx-auto max-w-4xl">
        <ul class="flex flex-wrap justify-around gap-5">
          <NuxtLink
            :to="
              localePath({
                name: 'product-lines-category-slug',
                params: { slug: category.urlSlug },
              })
            "
            class="group font-bold no-underline transition duration-300"
            v-for="category in categories?.items"
            :key="category.nid"
          >
            <li>
              <div class="flex max-w-32 flex-col items-center pb-8 text-center">
                <img
                  :src="getResourceUrl(category.image?.url)"
                  height="55"
                  width="55"
                  class="mb-5 transition duration-300 group-hover:-translate-y-2.5"
                  alt="Category icon"
                  loading="lazy"
                />

                <h2
                  class="font-pragmatica-ext group text-lg font-bold leading-tight"
                >
                  {{ category.title?.replaceAll('a ', 'a&nbsp;') }}
                </h2>
              </div>
            </li>
          </NuxtLink>
        </ul>
      </div>
    </div>
  </section>
</template>

<style lang="sass">
.content-block-intro
  p
    @apply mb-4

  .lead
    @apply whitespace-normal text-xl font-bold leading-[1.3]

  .font-extended
    font-family: 'Pragmatica Ext', sans-serif
</style>

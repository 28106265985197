<script setup lang="ts">
const { t } = useI18n()

useSeoMeta({
  title: t('pages.index.meta.title'),
  description: t('pages.index.meta.description'),
})
</script>

<template>
  <main>
    <HomepageHero />
    <HomepageProducts />
    <HomepageAbout />
    <HomepageNews :title="$t('base.news.title')" :is-home-page="true" />
    <NewsSubscribe />
    <HomepageSustainability />
    <HomepageTopSeries />
    <HomepageConfigurator />
    <HomepagePartner />
  </main>
</template>

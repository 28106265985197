<script setup lang="ts">
const localePath = useLocalePath()
const { locale } = useI18n()

const productLinesRepository = createProductLineRepository()

const { data: topSeriesData } = await useLazyAsyncData(
  'topSeries',
  () => {
    return Promise.all(
      topSeries.map((series) => {
        return productLinesRepository.getLineBySlug(series.slug)
      })
    )
  },
  {
    watch: [locale],
  }
)

const chosenSeries = ref(topSeriesData.value?.at(0))
</script>

<template>
  <div v-if="chosenSeries" class="container py-24">
    <div
      class="mb-10 flex flex-wrap items-baseline justify-between gap-x-10 gap-y-4"
    >
      <h2 class="font-pragmatica-ext text-xl font-bold">
        {{ $t('homepage.topSeries.title') }}
      </h2>
      <div class="flex flex-wrap gap-4 gap-x-5 gap-y-2 font-bold">
        <button
          v-for="seriesItem in topSeriesData"
          :key="seriesItem.nid"
          class="group relative h-fit"
          @click="chosenSeries = seriesItem"
        >
          <TypographyBaseBold>
            {{ seriesItem.title }}
          </TypographyBaseBold>
          <div
            :class="{
              'opacity-0': chosenSeries.title !== seriesItem.title,
            }"
            class="absolute bottom-[-1px] h-[1px] w-full bg-black transition-all duration-300 group-hover:bottom-[-8px] group-hover:opacity-100"
          />
        </button>
      </div>
    </div>
    <div class="flex gap-10 max-lg:flex-col lg:items-end">
      <div class="flex max-w-md flex-col gap-8">
        <p class="font-pragmatica-ext text-4xl font-bold">
          {{ chosenSeries.title }}
        </p>
        <p>
          {{ stripHtml(chosenSeries.description) }}
        </p>
        <div>
          <NuxtLink
            :to="
              localePath({
                name: 'products-slug',
                params: { slug: chosenSeries.urlSlug },
              })
            "
          >
            <UiButtonBlackOutline>Vše o sérii</UiButtonBlackOutline>
          </NuxtLink>
        </div>
      </div>
      <div class="aspect-[3/2]">
        <NuxtImg
          sizes="100vw sm:500px md:800px xl:1200px"
          class="h-full w-full object-cover object-bottom"
          :src="withHost(chosenSeries.backgroundImages[0].url)"
        />
      </div>
    </div>
  </div>
</template>

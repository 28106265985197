<script setup lang="ts">

const { getResourceUrl } = useResources()

const { locale } = useI18n()
const heroImageRef = ref()

const { data: slidesData } = await useLazyAsyncData(() =>
  createCarouselSlidesRepository().getAll()
)

const slides = computed(() => slidesData.value?.items ?? [])

// const imagesToPreload = computed(() =>
//   slides.value
//     .map((slide) => {
//       if (slide && slide.image) {
//         return {
//           rel: 'preload',
//           as: 'image',
//           href: getResourceUrl(slide.image.url) + '/10x10',
//         }
//       }
//     })
//     .filter((val): val is { rel: string; as: string; href: string } => !!val)
// )

// useHead({
//   link: imagesToPreload.value,
// })
</script>

<template>
  <section
    class="section relative mb-5 py-0"
    data-test="front-node-carousel-default-content"
  >
    <UiCarousel
      :opts="{
        loop: true,
      }"
      class="w-full @container"
    >
      <UiCarouselPrevious class="absolute left-0 top-0 z-10 h-full w-20">
        <span></span>
      </UiCarouselPrevious>
      <UiCarouselContent class="h-full">
        <UiCarouselItem
          v-for="(slide, index) in slides"
          :key="slide.nid"
          class="@5xl:container @4xl:min-w-[calc(100vw-250px)] @4xl:px-4 @5xl:min-w-0 @5xl:px-4 @[1200px]:px-8"
        >
          <div class="flex flex-col align-middle">
            <div class="relative">
              <div
                class="absolute bottom-0 left-1/2 z-10 flex w-full -translate-x-1/2 justify-between px-8 py-16 max-md:flex-col md:items-end lg:px-24 lg:py-12"
              >
                <div>
                  <div
                    class="pointer-events-none z-10 mb-8 flex flex-col justify-center"
                  >
                    <div class="pointer-events-auto inline-block">
                      <h1
                        class="font-pragmatica-ext group mb-0 text-5xl font-bold text-white drop-shadow-2xl md:text-6xl xl:text-8xl"
                      >
                        <NuxtLink
                          :to="locale + '/' + slide.buttonLink"
                          class="intro-carousel__title intro-carousel__title--light brand-decor no-underline"
                        >
                          <span>{{ slide.title?.[0] }}</span>
                          <span
                            class="inline-flex transition-transform duration-300 group-hover:translate-y-3 lg:group-hover:translate-y-5"
                          >
                            {{ slide.title?.[1] }}
                          </span>
                          <span>
                            {{
                              slide.title?.length > 2
                                ? slide.title?.substring(2)
                                : ''
                            }}
                          </span>
                        </NuxtLink>
                      </h1>
                    </div>
                  </div>
                  <div class="max-w-md">
                    <div
                      class="font-pragmatica-ext mb-4 whitespace-normal font-bold leading-tight text-white drop-shadow-[0_0_5px_rgba(0,0,0,0.5)] xl:mb-8 xl:text-xl"
                      v-html="slide.content"
                    />
                    <div class="my-auto max-lg:hidden">
                      <NuxtLink :to="locale + '/' + slide.buttonLink">
                        <UiButtonWhite>
                          {{ slide.buttonText }}
                        </UiButtonWhite>
                      </NuxtLink>
                    </div>
                  </div>
                </div>
                <div>
                  <UiCarouselNumbers class="ml-auto w-fit text-sm text-white" />
                </div>
              </div>
              <div
                class="absolute h-full w-full bg-gradient-to-l from-transparent via-transparent via-60% to-black opacity-90"
              ></div>
              <div
                class="h-[520px] max-h-[calc(100svh-9rem)] min-h-[500px] w-full overflow-hidden xl:h-[820px]"
              >
                <NuxtImg
                  v-if="slide.image?.fileType?.startsWith('image')"
                  height="100px"
                  width="100px"
                  ref="heroImageRef"
                  class="h-full w-full object-cover object-bottom transition-all duration-300"
                  :src="getResourceUrl(slide.image.url)"
                  sizes="400px sm:700px md:850px lg:1000px xl:1500px"
                  :alt="slide.image?.metaAlt || slide.title || 'Hero image'"
                  :title="slide.image?.metaTitle"
                  :loading="index === 0 ? 'eager' : 'lazy'"
                  :preload="index === 0"
                />
                <video
                  v-else
                  ref="heroImageRef"
                  :src="getResourceUrl(slide.image.url)"
                  autoplay
                  loop
                  muted
                  class="aspect-auto h-full w-full object-cover"
                ></video>
              </div>
            </div>
          </div>
        </UiCarouselItem>
      </UiCarouselContent>

      <UiCarouselNext class="absolute right-0 top-0 z-10 h-full w-20">
        <span></span>
      </UiCarouselNext>
    </UiCarousel>
  </section>
</template>

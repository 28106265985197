<script setup lang="ts">
import { useCarousel } from './useCarousel'
import type { WithClassAsProps } from './types'

const props = defineProps<WithClassAsProps>()

const { carouselApi } = useCarousel()

const selected = ref(0)

function scrollToSlide(index: number) {
  carouselApi.value?.scrollTo(index)
}

function updateSelected() {
  selected.value = carouselApi.value?.selectedScrollSnap() || 0
}

const listenerRegistered = ref(false)
watch(
  carouselApi,
  () => {
    if (carouselApi?.value && !listenerRegistered.value) {
      carouselApi.value?.on('scroll', updateSelected)
      carouselApi.value?.on('slidesChanged', updateSelected)
      listenerRegistered.value = true
    }
  },
  { immediate: true }
)
</script>

<template>
  <div :class="cn(props.class)">
    <button
      v-for="(_, index) in carouselApi?.scrollSnapList()"
      :key="index"
      :class="cn('p-1', props.class)"
      @click="scrollToSlide(index)"
    >
      <slot>
        <span
          :class="
            cn('', {
              'underline': selected === index,
            })
          "
        >
        0{{ index + 1 }}
      </span>
      </slot>
    </button>
  </div>
</template>
